<template>
  <div>
    <calendarTitle />
    <calendarMail />
    <calendarPassword />
    <saveBtn
      class="saveBtn"
      :callback="function() { checkData() }"
    />
  </div>
</template>
<script>
import def from "../default/";
export default {
  extends: def,
  components: {
    calendarTitle() {
      return import("../title");
    },
    calendarPassword() {
      return import("./password");
    },
    calendarMail() {
      return import("./mail");
    }
  },
  methods: {
    nextStep() {
      this.$router.push({ name: "privateTime" });
    },
    checkData() {
      var data = JSON.parse(
        JSON.stringify(this.$store.state.privateEvent.unsaved)
      );
      if (!data.title) {
        return false;
      }
      if (!data.email) {
        return false;
      }
      this.setSave();
    }
  }
};
</script>
